import React from "react"

import Container from "@ecom/ui/components/Container"
import img from "./img/img_1000.svg"
import { getDatesFromQuery } from "../../../utils/getDatesFromQuery"
import * as styles from "./promotion.module.scss"

export function Promotion() {
  const dates = getDatesFromQuery()

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <img src={img} alt="img" className={styles.img} />
        <div className={styles.text}>
          Совершите от 5 покупок* собственными или заемными средствами на общую сумму от 10 000
          руб.** три отчетных периода подряд (в Ваш текущий отчетный период – с {dates[0]} по{" "}
          {dates[1]}, и два cледующих – с {dates[2]} по {dates[3]} и с {dates[4]} по {dates[5]}), и
          мы подарим Вам 1000 баллов*** на ХАЛВУ!
        </div>
      </Container>
    </section>
  )
}
